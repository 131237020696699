import './ds-payment-method-selector.scss';
import { bindable, autoinject, observable, TaskQueue } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BlacklistService } from 'services/blacklist-service';
import { PaymentMethodThreshold, PaymentMethodWebsite, ThresholdOperationType, VerificationType } from 'services/models/purchase-flow/paymentMethodWebsite';
import { ChargebackService } from 'services/chargeback-service';
import { Helper } from 'resources/extensions/helper';
import { chargebackArrayToFilter } from 'resources/constants';
import { Currency } from 'services/models/purchase-flow/exchange';
import { WebsiteService } from 'services/website-service';
import { ProductService } from 'services/product-service';
import { ProductCategoryService } from 'services/product-category-service';
import { Router } from 'aurelia-router';
import { CurrencyService } from 'services/currency-service';

@autoinject()
export class DsPaymentMethodSelector {
    @bindable selectedPaymentMethod: PaymentMethodWebsite;
    @bindable givenCurrencySelected;
    @bindable home;
    @bindable forceCad;
    @bindable @observable user;
    @bindable preferredCurrency;
    @bindable phoneVerified;
    @bindable totalFunction;
    @bindable totalPrice;
    balancePaymentMethod: PaymentMethodWebsite;
    allPaymentMethods: PaymentMethodWebsite[];
    @bindable paymentMethods: PaymentMethodWebsite[];
    recentPaymentMethods: PaymentMethodWebsite[];
    thresholds: PaymentMethodThreshold[];
    search = '';
    validCountry = true;
    parent;
    userBlacklist;
    order;
    userHasChargebacks;
    chargebackArrayToFilter = chargebackArrayToFilter();
    activeCurrencies: Currency[];
    regex = /(cart|sell)/i;
    sellPageRoute;
    game;
    gameCurrencyPaymentMethod;

    constructor(
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private sessionService: SessionService,
        private toastService: ToastService,
        private eventAggregator: EventAggregator,
        private blacklistService : BlacklistService,
        private chargebackService: ChargebackService,
        private helper: Helper,
        private currencyService: CurrencyService,
        private websiteService: WebsiteService,
        private productService: ProductService,
        private productCategoryService: ProductCategoryService,
        private router: Router,
        private taskQueue: TaskQueue
    ) {
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        this.thresholds = await this.paymentMethodWebsiteService.getThresholds();
        this.user = await this.sessionService.refreshProfile();
        if (this.user) {
            this.userHasChargebacks = await this.chargebackService.userHasChargeback(this.user.id);
        }

        if (!this.paymentMethods) {
            this.paymentMethods = await this.paymentMethodWebsiteService.getByWebsite();
        }

        if (this.userHasChargebacks) {
            this.paymentMethods = this.helper.filterOutByArray(this.paymentMethods, this.chargebackArrayToFilter);
            return;
        }

        this.sellPageRoute = await this.websiteService.getRouteOrDefault('sell');
        if (this.gameCurrencyPaymentMethod?.live && this.regex.test(this.router.currentInstruction.config.moduleId)) {
            this.getCurrencyPaymentMethods();
        }

        if (this.user) {
            if (await this.checkUserInBlackList()) {
                return;
            }
            this.recentPaymentMethods = await this.paymentMethodWebsiteService.getRecentPaymentMethodByUserId();
            this.recentPaymentMethods?.forEach(x => {
                x.supportedCurrencies = this.paymentMethods.find(p => p.paymentMethodId === x.paymentMethodId)?.supportedCurrencies;
            });
        }

        this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
        this.balancePaymentMethod = this.paymentMethods.find(e => e.paymentMethod?.name === 'Balance');
        this.paymentMethods = this.paymentMethods.filter(e => e.paymentMethod?.name !== this.balancePaymentMethod?.paymentMethod?.name);
        this.checkIfUserAndMethodBalanceEnabled();
        this.validCountry = this.user.balance > 0 || await this.sessionService.isCountryValidForCartPage();
        if (!this.validCountry && !this.parent.isSoldOrder) {
            return this.toastService.showToast('Error', 'The country which you\'re attempting to access divicasales.com is not supported. Please contact support for assistance.', 'error');
        }
    }

    async userChanged() {
        this.userHasChargebacks = await this.chargebackService.userHasChargeback(this.user?.id);
        if (await this.checkUserInBlackList() && !this.userHasChargebacks) {
            return;
        }
        if (this.userHasChargebacks) {
            this.paymentMethods = this.helper.filterOutByArray(this.paymentMethods, this.chargebackArrayToFilter);
            return;
        }
        if (this.user?.balance <= 0) this.user.balanceEnabled = false;
    }

    checkIfUserAndMethodBalanceEnabled() {
        if (this.user?.balance > 0 && this.balancePaymentMethod) {
            this.user.balanceEnabled = true;
        }
    }

    handleSearchChange(event) {
        if (event?.target?.value) {
            this.search = event.target.value;
            if (this.paymentMethods.length) {
                for (const method of this.paymentMethods) {
                    method.show = method.paymentMethod?.name.toLowerCase().includes(this.search.toLocaleLowerCase());
                }
            }
        }
    }

    async selectPaymentMethod(method: PaymentMethodWebsite) {
        if (!method) {
            return;
        }
        const selector = document.getElementById('payment-selector');
        selector.classList.remove('show');
        const dropdownMenu = document.getElementById('payment-dropdown-menu');
        dropdownMenu.classList.remove('show');
        const paymentsList = ['crypto', 'coinpayments', 'btcpay', 'bitcart', 'bluesnap-checkout', 'skrill-direct', 'google-pay-direct', 'g2a'];
        if (this.helper.includesSome(method.paymentMethod.reference, paymentsList)) {
            if (this.helper.includesSome(method.paymentMethod.reference, paymentsList.slice(3))) {
                this.parent.summaryButtonState = this.parent.user && this.parent?.billing?.street && this.parent?.billing?.country && this.parent?.billing?.city && this.parent?.billing?.zip && this.parent.phoneVerified ? 'active' : 'disabled';
            } else {
                this.parent.summaryButtonState = this.parent.user && this.parent.phoneVerified ? 'active' : 'disabled';
            }
            this.parent.summaryButtonFunction = this.startOrder.bind(this);
            this.parent.summaryButtonText = 'Pay now';
            this.parent.shouldShowBlueLine = this.parent.user ? false : true;
        } else if (method !== this.selectedPaymentMethod && method.paymentMethod?.reference !== this.selectedPaymentMethod?.paymentMethod?.reference) {
            this.parent.shouldShowBlueLine = true;
        }
        this.selectedPaymentMethod = method;
        await this.checkUserInBlackList();

        if (this.totalFunction) {
            this.taskQueue.queueMicroTask(() => {
                this.totalFunction();
            });
        }

        //Force currency based on payment
        await this.forceCurrency(method);
    }

    clearSearch() {
        this.search = null;
    }

    startOrder() {
        this.parent.startOrder();
    }

    changeCryptoPaymentSelected(cryptoAbbreviation: string) {
        const paymentMethod = this.paymentMethods.find(p => p.paymentMethod.cryptoAbbreviation === cryptoAbbreviation);
        this.selectPaymentMethod(paymentMethod);
    }

    async forceCurrency(method: PaymentMethodWebsite) : Promise<void> {
        let currencyToSent = '';
        if (!this.activeCurrencies) this.activeCurrencies = await this.currencyService.getActiveCurrenciesByWebsite();
        if (method.supportedCurrencies?.length) {
            const currencies = method.supportedCurrencies.map(c => this.activeCurrencies.find(ac => ac.id === c.currencyId)?.code);
            currencyToSent = currencies[0];
        } else {
            const previousCurrency = await this.sessionService.getPreviousCurrency();
            previousCurrency !== 'undefined' ? currencyToSent = previousCurrency : '';
        }
        this.eventAggregator.publish('force-currency', { currency: currencyToSent, oldCurrency: this.preferredCurrency, currentPaymentMethodSelected: method });
    }

    async checkUserInBlackList() {
        if (!this.thresholds) this.thresholds = await this.paymentMethodWebsiteService.getThresholds();
        const banForPaymenthMethod = await this.sessionService.checkIfUserByPaymentMethodInBlackList(this.thresholds.filter(t => t.paymentMethodId === this.selectedPaymentMethod?.paymentMethodId));
        if (banForPaymenthMethod.paymentMethods) {
            this.paymentMethods = null;
            this.selectedPaymentMethod = null;
            this.parent.summaryButtonState = 'disabled';
            this.parent.userBanByPaymenthMethod = true;
            return true;
        }
        if (banForPaymenthMethod.filterPaymentMethods) {
            this.paymentMethods = this.paymentMethods?.filter(x =>
                !this.parent.thresholds.find(t => t.paymentMethodId === x.paymentMethodId && t.verificationTypeList.includes(VerificationType.ID) && t.operationTypeList.includes(ThresholdOperationType.Sell)) &&
                !this.parent.thresholds.find(t => t.paymentMethodId === x.paymentMethodId && t.verificationTypeList.includes(VerificationType.Phone) && t.operationTypeList.includes(ThresholdOperationType.Sell))
            );
            this.selectedPaymentMethod = banForPaymenthMethod.selectedPaymentMethod ? null : this.selectedPaymentMethod;
            this.parent.summaryButtonState = banForPaymenthMethod.selectedPaymentMethod ? 'disabled' : 'active';
        }
        return false;
    }

    async getCurrencyPaymentMethods() {
        const gamesNav = await this.productCategoryService.getNavCategory('currency', 'DS');
        const games = gamesNav.gameForNav.sort((a, b) => a.name?.toLowerCase()?.localeCompare(b.name?.toLowerCase()));
        let gameIds = [];
        games.map(x => gameIds.push(x.id));
        const cart = await this.sessionService.getCart();
        const cartIds = [];
        cart?.filter(x => ['Dynamic Service', 'Dynamic Unique Names'].every(y => y !== x.name) && x.productCategory?.name === 'Currency').map(x => cartIds.push(x.gameId.toString()));
        gameIds = gameIds.filter(x => !cartIds.includes(x));
        const result = await this.productService.getProductGameForPaymentMethodCurrencies(gameIds);

        let gamePaymentMethods = [];
        for (const gameId of gameIds) {
            if (result.length) {
                const product = result.find(p => p.gameId === parseInt(gameId));
                if (product?.price) {
                    games.find(y => y.id === gameId).price = product?.price ?? 0;
                    gamePaymentMethods.push(
                        {
                            paymentMethod: {
                                id: this.gameCurrencyPaymentMethod.paymentMethod.id,
                                name: games.find(y => y.id === gameId).name,
                                reference: 'game-currency',
                                imagePath: product?.imagePath ?? null
                            },
                            game: games.find(y => y.id === gameId),
                            fee: this.gameCurrencyPaymentMethod.fee,
                            isGame: true,
                        }
                    );
                }
            }
        }

        gamePaymentMethods = gamePaymentMethods.filter(x => !this.paymentMethods.find(y => y.game?.id === x.game?.id));
        this.paymentMethods = this.paymentMethods.concat(gamePaymentMethods);
    }
}
