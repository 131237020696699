import { autoinject } from 'aurelia-framework';
import { SessionService } from './session-service';
import { ToastService } from './toast-service';
import { ErrorPagesRedirection } from 'resources/extensions/error_redirections';
const AUTHORIZATION_HEADER = 'Authorization';

@autoinject()
export class ApiInterceptor {
    constructor(private sessionService: SessionService, private toastService: ToastService, private errorPagesRedirection: ErrorPagesRedirection) {}

    async request(request: Request) {
        try {
            if (request.headers.has('X-Skip-Interceptor')) {
                return request;
            }

            const storedToken = await this.sessionService.getToken();

            if (storedToken && !request.headers.get(AUTHORIZATION_HEADER)) {
                const bearerToken = `Bearer ${storedToken}`;
                request.headers.append(AUTHORIZATION_HEADER, bearerToken);
            }

            return request;
        } catch (e) {
            console.log(e);
            await this.toastService.showToast('Something went wrong.', 'If this error continues, please contact support.', 'error');
        }
    }

    async responseError() {
        return null;
    }

    async response(response) {
        try {
            this.errorPagesRedirection.handleRateLimitToken(response);

            if (response?.status === 429) {
                return this.errorPagesRedirection.handleRateLimitHttpStatus(response);
            }
            if (response?.status >= 400 && response?.status <= 599) {
                if (response?.status === 404) return;
                if (response?.status === 401) {
                    await this.sessionService.clearSession();
                    if (!response.headers?.get('content-type')?.includes('application/json')) return;
                }
                const data = await response.json();
                let msg = data.Message || data.message || (typeof data === 'string' ? data : undefined);
                if (msg) {
                    const titleDependingOnMessage = 'Error';
                    msg.endsWith('.') ? msg : msg = msg + '.';
                    await this.toastService.showToast(titleDependingOnMessage, msg.replace(/(?:\r\n|\r|\n)/g, '<br />'), 'error');
                }

                if (data.validationErrors) {
                    let errorMsg = '';
                    Object.keys(data.validationErrors).forEach((key) => {
                        data.validationErrors[key].forEach(m => {
                            errorMsg += `${m}<br>`;
                        });
                    });
                    await this.toastService.showToast('Error', errorMsg, 'error');
                }
                new Error(msg);
            }
            return response;
        } catch (e) {
            console.log(e);
            await this.toastService.showToast('Something went wrong', 'If this error continues, please contact support.', 'error');
        }
    }
}
