import { EventAggregator } from 'aurelia-event-aggregator';
import { PageContentArea } from 'services/models/page/pageContentArea';
import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class PageContentAreaService {
    path = 'PageContentArea';
    retrievedContent: PageContentArea[] = [];

    constructor(private api: ApiService, private eventAggregator: EventAggregator, private helper: Helper) { }

    async getById(id: number) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getByKey(key: string) {
        const foundContent = this.retrievedContent.find(x => x.key === key);
        if (foundContent) {
            return foundContent;
        } else {
            return null;
        }
    }

    async getByPageId(pageId: number) {
        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode()
        };

        const content = await this.api.doGet(this.path + '/ByPage', params);
        content?.forEach((e) => {
            if (!this.retrievedContent.find(x => x.key === e.key)) {
                this.retrievedContent.push(e);
            }
        });
        this.publishContent();
        return this.retrievedContent;
    }

    async getByPageIdAndGame(pageId, gamePage) {
        if (!this.retrievedContent.some(x => x.key?.includes(gamePage)) && pageId && gamePage) {
            const params = {
                pageId: pageId,
                websiteShortCode: websiteShortCode(),
                gamePage: gamePage
            };
            const content = await this.api.doGet(this.path + '/ByPageAndGame', params);
            this.retrievedContent.push(...content);

        }
        this.publishContent();
        return this.retrievedContent;
    }


    publishContent() {
        this.helper.debounce(this, 'sendingContent', 'contentTimeout', 350, () =>
            this.eventAggregator.publish('retrieved-content-changed', this.retrievedContent)
        );
    }
}
