import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class SiteDown {
    @bindable redirectTo: string;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    activate() {
        const params = new URLSearchParams(window.location.search);
        const redirectTo = params.get('redirectTo');

        if (redirectTo) {
            this.redirectTo = decodeURIComponent(redirectTo);
            return;
        }

        this.redirectTo = '/';
    }
}
