import './bug-bounty.scss';
import { Router } from 'aurelia-router';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-framework';
import { WebsiteService } from 'services/website-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class BugBounty {
    constructor(
        private router: Router,
        private pageContentAreaService: PageContentAreaService,
        private websiteService: WebsiteService,
        private eventAggregator: EventAggregator,
        private helper: Helper) { }

    bugBountyPageContentArea;
    viewingAsAdmin;
    adminViewSubscription;

    async activate() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        const pageId = pages.find(x => x.name === 'Bug Bounty')?.id;
        this.bugBountyPageContentArea = await this.pageContentAreaService.getByPageId(pageId);
    }

    attached() {
        this.handleEventSubscriptions();
    }

    detached() {
        this.adminViewSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.adminViewSubscription = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }
}

