import './cart.scss';
import { InterComStylingService } from 'services/intercom-styling-service';
import { ScriptService } from 'services/script-service';
import { CustomerService } from 'services/customer-service';
import { SiteSettingService } from 'services/site-setting-service';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { PaymentMethodWebsiteService } from 'services/payment-method-website-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { GameModeService } from 'services/game-mode-service';
import { getCanadaProvinces, chargebackArrayToFilter, automaticPaymentMethods, automaticPaymentMethodsWithout, automaticPaymentMethodsWithout3DS, paymentMethodListForShowingButton } from 'resources/constants';
import { Billing } from 'services/models/purchase-flow/billing';
import { OrderService } from 'services/order-service';
import { ToastService } from 'services/toast-service';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { websiteShortCode } from 'environment';
import { WebsiteService } from 'services/website-service';
import { Helper } from 'resources/extensions/helper';
import { PaymentMethodWebsite } from 'services/models/purchase-flow/paymentMethodWebsite';
import { ChargebackService } from 'services/chargeback-service';
import { TotalSpentByUser, User } from 'services/models/user';
import { CurrencyService } from 'services/currency-service';
import { isPointWithinRadius } from 'geolib';
import { PaymentMethodThreshold } from 'services/models/purchase-flow/paymentMethodWebsite';
import { VGSDebitCreditCardService } from 'services/vgs-service';
import PaymentFunctionality from 'resources/extensions/payment_functionality';
import { BlockActionLogService } from 'services/block-action-log-service';

@autoinject()
export class Cart extends PaymentFunctionality {
    @bindable loggingIn;
    @bindable validCoupon;
    @bindable usingPreviousCard;
    @bindable showBillingGreenCheckMark;
    @bindable showBillingErrorCheckMark;
    @bindable phoneInputLoading;
    @bindable loadingFromAuth;
    @bindable selectedPaymentMethod: PaymentMethodWebsite;
    @bindable veriffStep;
    @bindable qrCode;
    idealBank;
    bluesnapHostedUrl;
    user: User;
    products;
    product;
    quantity = 10;
    customer;
    totalPrice = 0.00;
    result;
    checkoutType = '';
    step = 1;
    chargeData;
    loading;
    paymentMethods: PaymentMethodWebsite[];
    paymentMethodSelector;
    shouldShowPhoneNumber = true;
    showingPhoneAuthToken = false;
    shouldShowBlueLine = true;
    stakeTrade = false;
    cart;
    subs;
    meetsMinimum;
    meetsMaximum;
    paymentFee = 0;
    smallOrderFee = 0;
    smallOrderMinimumAmount = 0;
    deliveryFee = 0;
    hstValue = 0;
    couponDiscount = 0;
    subtotal = 0;
    couponCode;
    coupon;
    balanceAmount;
    balanceFee;
    billing: Billing = {};
    firstNameOnCard;
    lastNameOnCard;
    phoneVerified: boolean;
    insurance;
    validCountry = true;
    checkoutInstrumentId;
    checkoutDeviceSessionId;
    bluesnapToken;
    cardLastFourDigits;
    cardType;
    threeDResult;
    cardCvv;
    paymentProcessing;
    gameModes;
    balancePaymentMethod: PaymentMethodWebsite;
    preferredCurrency;
    userProxy;
    idVerificationCriteriaMet;
    currencyUpdateSubscription;
    phoneSubscriber;
    userSubscriber;
    cartSubscriber;
    recalculateSubcriber;
    veriffSubscriber;
    vpnDetectionSubscriber;
    insuranceName;
    insuranceFee;
    countryToUse;
    stateToUse;
    paysafeCardId;
    paysafeBillingAddressId;
    paysafeAuthenticationId;
    bluesnapViewModel;
    paysafeViewModel;
    smallStakeFee;
    smallStakeFeeThreshold;
    remainingAmount;
    smallOrderFeeAmount;
    veriffViewModel;
    postCodeValidator;
    postCodeValidatorExistsForCountry;
    disclaimerTitle;
    disclaimerMessage;
    paysafeVaultedProfile;
    balanceAmountCalculated;
    totalPriceCalculated;
    currencyPageRoute;
    paymentToken;
    checkoutVaultedProfile;
    checkoutViewModel;
    googlePayPaymentData;
    applePayPaymentData;
    params;
    sizeChanged;
    googlePayViewModel;
    width;
    thresholds: PaymentMethodThreshold[] = [];
    totalSpentByUser: TotalSpentByUser;

    summaryButtonText = 'Pay now';
    summaryButtonState = 'disabled';
    chargebackArrayToFilter = chargebackArrayToFilter();
    automaticPaymentMethods = automaticPaymentMethods();
    automaticPaymentMethodsWithout = automaticPaymentMethodsWithout();
    automaticPaymentMethodsWithout3DS = automaticPaymentMethodsWithout3DS();
    paymentMethodListForShowingButton = paymentMethodListForShowingButton();
    orderDocuments = [];
    triggeredStartOrder;
    userBanByPaymenthMethod = false;
    geoCoderLatitude: number;
    geoCoderLongitude: number;
    siteSettingsKeys;
    vgsData;
    threeDsData;
    forceVerification;
    stripeRadarSession;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private router: Router,
        private sessionService: SessionService,
        private paymentMethodWebsiteService: PaymentMethodWebsiteService,
        private eventAggregator: EventAggregator,
        private gameModeService: GameModeService,
        private orderService: OrderService,
        private toastService: ToastService,
        private customerService: CustomerService,
        private siteSettingService: SiteSettingService,
        private scriptService: ScriptService,
        private intercomStylingService: InterComStylingService,
        private websiteService: WebsiteService,
        public helper: Helper,
        private chargebackService: ChargebackService,
        private currencyService: CurrencyService,
        private vgsDebitCreditCardService: VGSDebitCreditCardService,
        private blockActionLogService: BlockActionLogService
    ) {
        super(helper);
        this.postCodeValidator = postcodeValidator;
        this.postCodeValidatorExistsForCountry = postcodeValidatorExistsForCountry;
        scriptService.injectKountScript();
        scriptService.injectPaysafeScript();
        scriptService.injectPaysafe3DSScript();
        scriptService.injectGoogleScript();
        scriptService.injectCheckoutScript();
        scriptService.injectCheckoutRiskScript();
        scriptService.injectGooglePayScript();
        scriptService.injectApplePayScript();
        scriptService.initiateKountFraudScript();
    }

    async activate(params) {
        let pages;
        this.params = params;
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        [this.cart, pages] = await Promise.all([
            this.sessionService.getCart(),
            this.websiteService.getPagesByWebsiteShortcode()
        ]);

        await this.pageContentAreaService.getByPageId(pages.find(x => x.name === 'Cart')?.id);
        this.currencyPageRoute = pages.find(x => x.name === 'Currency')?.routeName ?? 'currency';

        await this._updateCart();

        if (!this.cart || this.cart?.length < 1) {
            this.router.navigateToRoute('currency');
            return;
        }
    }

    async attached() {
        try {
            this.siteSettingsKeys = await this.siteSettingService.getSiteSettings();
            this.intercomStylingService.handleStyles(this.width);
            this.handleEventSubscriptions();

            if (this.params.orderId && this.params.status === 'rejected') {
                const response = await this.orderService.updateCheckoutStatus(this.params.orderId, 'rejected');
                if (response) {
                    this.toastService.showToast('Error', 'Refresh your session and try again, or choose Debit/Credit (Paysafe) in the payment method drop-down list.', 'error');
                }
                this.router.navigateToRoute('cart');
            }

            [this.preferredCurrency, this.paymentMethods, this.user, this.gameModes, this.userProxy, this.validCountry, this.thresholds] = await Promise.all([
                this.sessionService.getCurrency(),
                this.paymentMethodWebsiteService.getByWebsite(),
                this.sessionService.getProfile(),
                this.gameModeService.getAll(),
                this.sessionService.getUserProxy(),
                this.sessionService.isCountryValidForCartPage(),
                this.paymentMethodWebsiteService.getThresholds()
            ]);

            this.validCountry = this.user?.balance > 0 || this.validCountry;

            if ((this.userProxy?.vpn || this.userProxy?.tor) && this.userProxy.ip !== '127.0.0.1' && this.userProxy.host !== 'localhost') {
                this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Please ensure you\'re using a genuine internet connection before making a purchase. The use of a VPN or TOR may result in failed attempts or a refund' });
            }

            if (this.user) {
                if (this.userBanByPaymenthMethod) {
                    return;
                }
                if (this.validCountry || (!this.validCountry && this.user?.balance > 0)) {
                    this.idVerificationCriteriaMet = await this.customerService.verificationCategoryCriteriaMet('ID & Selfie Verification');
                    const userHasChargebacks = await this.chargebackService.userHasChargeback(this.user.id);
                    this.paymentMethodSelector?.selectPaymentMethod(this.paymentMethods.find(method => method.paymentMethodId === this.user.paymentMethodId
                        && (userHasChargebacks ? this.chargebackArrayToFilter.some(y => method.paymentMethod?.reference?.includes(y)) : true)
                    ));
                }
                this.firstNameOnCard = this.user?.firstName;
                this.lastNameOnCard = this.user?.lastName;
                this.totalSpentByUser = await this.customerService.getTotalSpentByUser(this.user.id);
            }

            this.balancePaymentMethod = this.paymentMethods.find(e => e.paymentMethod?.name === 'Balance');

            this.setBillingForUser();
            await this.getTotal();
            this.helper.handleGtagEvent('view_cart', this.cart, 'USD', this.totalPrice);
        } catch (e) {
            this.handleError(e);
        }
    }

    detached() {
        this.userSubscriber?.dispose();
        this.phoneSubscriber?.dispose();
        this.currencyUpdateSubscription?.dispose();
        this.cartSubscriber?.dispose();
        this.recalculateSubcriber?.dispose();
        this.veriffSubscriber?.dispose();
        this.vpnDetectionSubscriber?.dispose();
        this.sizeChanged?.dispose();
        this.helper.removeGtagEvent();
    }

    private async _updateCart() {
        const oldCart = await this.sessionService.getCart(true);
        this.eventAggregator.publish('user-updated', { user: await this.sessionService.getProfile() });

        if (oldCart?.length) {
            for (const item of oldCart) {
                await this.sessionService.saveCart(this.cart, true, null, null, null, item, true, true);
            }
        }

        this.cart = await this.sessionService.getCart(false, true);
        this.eventAggregator.publish('cart-updated', { cart: this.cart });
    }

    handleEventSubscriptions() {
        this.currencyUpdateSubscription = this.eventAggregator.subscribe('currency-updated', payload => {
            this.preferredCurrency = payload.currency;
        });

        this.phoneSubscriber = this.eventAggregator.subscribe('phone-updated', payload => {
            if (payload.successful) {
                this.user.phoneNumberConfirmed = true;
                this.getTotal();
            }
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload) => {
            this.user = payload.user;
            const paymentsList = ['bluesnap-checkout', 'skrill-direct', 'crypto', 'coinpayments', 'btcpay', 'bitcart', 'google-pay-direct'];
            const paymentList = paymentsList.some(payment => this.selectedPaymentMethod?.paymentMethod?.reference.includes(payment));
            if (this.user && paymentList && !this.checkIfButtonInLoadingState()) {
                if (this.selectedPaymentMethod?.paymentMethod?.reference.includes('bluesnap-checkout') || this.selectedPaymentMethod?.paymentMethod?.reference.includes('skrill-direct') || this.selectedPaymentMethod?.paymentMethod?.reference.includes('google-pay-direct')) {
                    this.billing.street && this.billing.country && this.billing.city && this.billing.zip ? this.summaryButtonState = 'active' : this.summaryButtonState = 'disabled';
                } else {
                    this.summaryButtonState = 'active';
                }
                this.shouldShowBlueLine = false;
            }

            if (this.user?.idVerified && this.veriffStep && !this.checkIfButtonInLoadingState()) {
                this.summaryButtonState = 'active';
            }

            if (this.user) {
                this.idVerificationCriteriaMet = await this.customerService.verificationCategoryCriteriaMet('ID & Selfie Verification');
                this.validCountry = this.user?.balance > 0 || await this.sessionService.isCountryValidForCartPage();
                this.totalSpentByUser = await this.customerService.getTotalSpentByUser(this.user.id);
            }

            setTimeout(() => {
                this.loadingFromAuth = false;
            }, 1000);
            this.getTotal();
        });

        this.cartSubscriber = this.eventAggregator.subscribe('cart-updated', payload => {
            if (payload.updateWithoutRefresh) {
                this.cart.splice(payload.index, 1);
            } else {
                this.cart = payload.cart;
            }
            this.getTotal();
        });

        this.recalculateSubcriber = this.eventAggregator.subscribe('recalculate-cart', () => {
            this.getTotal();
        });

        this.veriffSubscriber = this.eventAggregator.subscribe('veriff-verification', payload => {
            if (payload.idVerified) {
                if (this.selectedPaymentMethod?.paymentMethod?.reference === 'manual'
                    || this.selectedPaymentMethod?.paymentMethod?.reference === 'zelle'
                    || this.selectedPaymentMethod?.paymentMethod?.reference === 'alipay'
                    || this.selectedPaymentMethod?.paymentMethod?.reference === 'cash'
                    || this.selectedPaymentMethod?.paymentMethod?.reference === 'western-union'
                    || this.selectedPaymentMethod?.paymentMethod?.reference === 'interac') {
                    this.summaryButtonState = 'active';
                } else if (!this.triggeredStartOrder) {
                    this.triggeredStartOrder = true;
                    this.startOrder();
                }
            } else {
                this.paymentProcessing = false;
                this.summaryButtonState = 'valid';
            }
        });

        this.vpnDetectionSubscriber = this.eventAggregator.subscribe('vpn-detection', async (payload) => {
            if ((payload.vpn || payload.tor) && payload.ip !== '127.0.0.1' && payload.host !== 'localhost') {
                this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Please ensure you\'re using a genuine internet connection before making a purchase. The use of a VPN or TOR may result in failed attempts or a refund' });
            }
        });

        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            if (this.selectedPaymentMethod?.paymentMethod?.reference.includes('google-pay-direct') && this.user) {
                const element = document.querySelector('.ds-google-pay-box');
                if (element.children.length <= 0) {
                    this.googlePayViewModel.createAndAddButton();
                }
            }
        });
    }

    validCouponChanged() {
        this.coupon = this.validCoupon;
        this.getTotal();
    }

    setBillingForUser() {
        this.billing.street = this.user?.address;
        this.billing.country = this.user?.country;
        this.billing.city = this.user?.city;
        this.billing.state = this.user?.state;
        this.billing.zip = this.user?.zip;
    }

    setCheckout(type) {
        if (type.minimum > (this.totalPrice as number)) {
            return;
        }
        this.selectedPaymentMethod = type;
        if (this.user) {
            this.customerService.setPaymentMethod(this.selectedPaymentMethod.paymentMethodId);
        }
        this.getTotal();
    }

    displayDisclaimerForMethod(selectedPaymentMethod: PaymentMethodWebsite): boolean {
        if (selectedPaymentMethod?.paymentMethod?.reference.includes('electronic-check-bluesnap-checkout')) {
            this.disclaimerTitle = 'Payment information';
            this.disclaimerMessage = `${selectedPaymentMethod?.paymentMethod?.name} can take up to 3 to 5 business days to consolidate the payment.`;
            return true;
        } else if (selectedPaymentMethod?.paymentMethod?.reference === 'crypto') {
            this.disclaimerTitle = 'Please be cautious of the network you are selecting to fulfill a payment';
            this.disclaimerMessage = 'Many tokens have a similar address format as Ethereum and share the same properties' +
                ' of the ERC20 standard, however, they are not the same. Other tokens are on a separate network,' +
                ' which Coinbase currently does not support. Please be advised that if you select a network outside' +
                ' of Coinbase, it may result in a permanent loss of funds.';
            return true;
        }
        return false;
    }

    async handleError(e) {
        console.log(e);
        this.summaryButtonState = 'active';
        this.loading = false;
        this.customer = await this.sessionService.refreshProfile();
        if (this.selectedPaymentMethod?.paymentMethod?.reference === 'bluesnap') {
            this.bluesnapViewModel?.loadBlueSnap();
        } else if (this.selectedPaymentMethod?.paymentMethod?.reference === 'paysafe') {
            this.paysafeViewModel?.handleUsingPreviousCardIfExisting();
        } else if (this.selectedPaymentMethod?.paymentMethod?.reference === 'checkout') {
            this.checkoutViewModel?.handleUsingPreviousCardIfExisting();
        }
        this.paymentProcessing = false;
    }

    async checkForVerification() {
        const result = await this.paymentMethodWebsiteService.getVerificationThresholds(this.totalSpentByUser, this.selectedPaymentMethod, this.totalPrice);
        this.phoneVerified = result.amountRequiresPhoneVeriff ? this.user?.phoneNumberConfirmed : true;
        if (this.checkIfButtonInLoadingState()) return;
        if (!this.phoneVerified) return this.summaryButtonState = 'disabled';
        if ((this.helper.includesWithout(this.selectedPaymentMethod?.paymentMethod?.reference, ['manual', 'zelle', 'alipay', 'cash', 'western-union', 'interac'], ['bluesnap-checkout', 'coinpayments']) && !this.user?.idVerified && result?.amountRequiresVeriff)) {
            this.veriffStep = true;
            this.shouldShowBlueLine = true;
            this.summaryButtonState = 'disabled';
        } else {
            this.veriffStep = false;
            this.shouldShowBlueLine = false;
            this.summaryButtonState = 'active';
        }
        return this.summaryButtonState;
    }

    customerFullNameStyleChange(showGreenCheckMark) {
        if (this.checkIfButtonInLoadingState()) return;
        showGreenCheckMark ? this.summaryButtonState = 'active' : this.summaryButtonState = 'disabled';
    }

    async getTotal() {
        let totalPrice = 0;
        let deliveryFee = 0;
        let paymentFee = 0;
        let smallOrderFee = 0;
        let smallOrderMinimumAmount = 0;
        let couponDiscount = 0;
        let insuranceFee = 0;
        let hstValue = 0;
        let subtotal = 0;
        this.balanceAmount = 0;
        this.balanceAmountCalculated = false;
        this.totalPriceCalculated = false;
        this.balanceFee = 0;
        let stakeTrade = false;
        const smallStakeFee = this.siteSettingsKeys?.find(x => x.key === 'SmallStakeFee');
        const smallStakeFeeThreshold = this.siteSettingsKeys?.find(x => x.key === 'SmallStakeFeeThreshold');
        const calculateHst = this.siteSettingsKeys?.find(x => x.key === 'CalculateHst');
        const insuranceNames = [];
        this.insuranceName = '';

        for (const item of this.cart) {
            item.total = parseInt(item.quantity?.toString().replaceAll(',', '')) * item.price;

            subtotal += item.total;
            if (item.deliveryMethod) {
                item.deliveryMethodId = item.deliveryMethod.id;
                deliveryFee += item.total * (item.deliveryMethod?.markupPercent / 100);
            }

            if (item.deliveryMethodId === 3) {
                stakeTrade = true;
            }

            if (item.fee) {
                const totalBefore = item.total;
                if (item.fee.fee > 0) {
                    item.total *= ((item.fee?.fee / 100) + 1); //Insurance for Accounts
                    insuranceFee += (item.total - totalBefore);
                    insuranceNames.push(item.fee.displayName ?? item.fee.name);
                    this.insuranceName = insuranceNames.join('  ');
                    this.eventAggregator.publish('insurance-updated', this.insuranceName);
                }
            }
            totalPrice += item.total;
        }

        totalPrice = parseFloat(totalPrice.toFixed(2));

        //This is the price before adding on the fees.
        this.cart.itemPrices = totalPrice;
        if (this.coupon) {
            couponDiscount = this.getCouponDiscount(this.cart, this.coupon);
        }

        if (this.coupon?.maximumDiscount && couponDiscount > this.coupon?.maximumDiscount) {
            couponDiscount = this.coupon.maximumDiscount;
        }

        if (stakeTrade && totalPrice <= smallStakeFeeThreshold.value) {
            deliveryFee += Number(smallStakeFee.value);
        }

        let remainingAmount = totalPrice;
        let smallOrderFeeAmount = 0;
        if (this.user?.balanceEnabled && this.balancePaymentMethod) {
            const updateBalanceAmount = () => {
                this.balanceAmount = totalPrice + deliveryFee;
                if (remainingAmount <= 0) this.balanceAmount -= couponDiscount;
            };

            updateBalanceAmount();

            this.balanceFee = this.balanceAmount * this.balancePaymentMethod.fee / 100;

            totalPrice += this.balanceFee;
            hstValue = await this.calculateHstAmount(calculateHst, this.balancePaymentMethod, totalPrice);
            totalPrice += hstValue;

            updateBalanceAmount();

            remainingAmount = totalPrice - this.balanceAmount;

            updateBalanceAmount();

            if (this.user.balance <= this.balanceAmount) this.balanceAmount = this.user.balance;
            if (this.balanceAmount > this.user.balance) this.balanceAmount += deliveryFee;
            if (this.balanceAmount > this.user.balance) this.balanceAmount += hstValue;
            if (this.balanceAmount > this.user.balance) this.balanceAmount += smallOrderFeeAmount;
        }

        remainingAmount = remainingAmount - couponDiscount;

        smallOrderFee = 0;
        smallOrderMinimumAmount = 0;
        paymentFee = 0;
        if (remainingAmount > 0) {
            if (remainingAmount < this.selectedPaymentMethod?.smallOrderMinimumAmount) {
                smallOrderFee = this.selectedPaymentMethod.smallOrderFee;
                smallOrderMinimumAmount = this.selectedPaymentMethod.smallOrderMinimumAmount;
                smallOrderFeeAmount = smallOrderFee;
            } else {
                smallOrderFeeAmount = 0;
            }
            remainingAmount += smallOrderFee;
            smallOrderFeeAmount = smallOrderFee;

            if (this.selectedPaymentMethod) {
                paymentFee = parseFloat((remainingAmount * (this.user?.balanceEnabled && remainingAmount <= 0 ? this.balancePaymentMethod.fee : this.selectedPaymentMethod?.additionalInternalFee > 0 ? (this.selectedPaymentMethod?.additionalInternalFee + this.selectedPaymentMethod.fee) / 100 : this.selectedPaymentMethod.fee / 100)).toFixed(2));
            }
            hstValue += await this.calculateHstAmount(calculateHst, this.selectedPaymentMethod, remainingAmount);
        }

        this.totalPrice = totalPrice + deliveryFee + paymentFee + smallOrderFeeAmount - this.balanceAmount;
        if ((this.totalPrice - couponDiscount) >= 0) this.totalPrice -= couponDiscount;
        else this.totalPrice -= this.totalPrice;
        this.totalPriceCalculated = true;
        this.deliveryFee = deliveryFee;
        this.paymentFee = paymentFee;
        this.couponDiscount = couponDiscount;
        this.smallOrderFee = smallOrderFee;
        this.smallOrderMinimumAmount = smallOrderMinimumAmount;
        this.hstValue = hstValue;
        this.insuranceFee = insuranceFee;
        this.subtotal = subtotal;

        if (!this.checkIfButtonInLoadingState()) this.summaryButtonState = await this.checkForVerification();

        this.checkMinAndMaxValues();
        this.loading = false;
    }

    async calculateHstAmount(calculateHst, paymentMethodWebsite: PaymentMethodWebsite, amount) {
        let result = 0;
        if (calculateHst && parseInt(calculateHst?.value) > 0 && paymentMethodWebsite?.calculateTaxes) {
            if (this.helper.includesWithout(paymentMethodWebsite.paymentMethod?.reference, this.automaticPaymentMethods, this.automaticPaymentMethodsWithout)) {
                this.countryToUse = this.billing.country;
            } else {
                this.countryToUse = this.user?.country ? this.user.country : await this.sessionService.getCountry();
                this.stateToUse = this.user?.state ? this.user.state : await this.sessionService.getState();
            }
            if (this.countryToUse === 'CA') {
                const caProvinces = getCanadaProvinces();
                let province = caProvinces.find(x => x.abbreviation === this.stateToUse);
                if (this.helper.includesWithout(paymentMethodWebsite.paymentMethod?.reference, this.automaticPaymentMethods, this.automaticPaymentMethodsWithout)) {
                    province = caProvinces.find(x => x.abbreviation === this.billing.state);
                }
                if (province) {
                    result = parseFloat((amount * (province.hst)).toFixed(2));
                }
            }
        }

        return result;
    }

    checkMinAndMaxValues() {
        if (this.selectedPaymentMethod) {
            if (this.selectedPaymentMethod?.payWithBalance && this.balancePaymentMethod) {
                this.meetsMinimum = this.selectedPaymentMethod.payWithBalance && this.balancePaymentMethod.minimum ? this.balancePaymentMethod.minimum <= (this.totalPrice + this.balanceAmount) : true;
                this.meetsMaximum = this.selectedPaymentMethod.payWithBalance && this.balancePaymentMethod.maximum ? this.balancePaymentMethod.maximum >= (this.totalPrice + this.balanceAmount) : true;
            } else {
                this.meetsMinimum = this.selectedPaymentMethod.minimum ? this.selectedPaymentMethod.minimum <= this.totalPrice + this.couponDiscount : true;
                this.meetsMaximum = this.selectedPaymentMethod.maximum ? this.selectedPaymentMethod.maximum >= this.totalPrice + this.couponDiscount : true;
            }
        }
    }

    async cartProductsValidation() {
        if (!await this.sessionService.isCountryValidForCartPage() && this.user.balanceEnabled && this.user.balance > 0) {
            this.toastService.showToast('Error', 'The country which you\'re attempting to access divicasales.com is not supported. Please contact support for assistance.', 'error');
            return false;
        }

        if (this.meetsMinimum || this.meetsMaximum) {
            if (!this.meetsMinimum) {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Error', `${this.selectedPaymentMethod?.payWithBalance ? 'Balance' : 'Payment method'} minimum amount not met.`, 'error');
                return false;
            }
            if (!this.meetsMaximum) {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Error', `Over ${this.selectedPaymentMethod?.payWithBalance ? 'balance' : 'payment method'} maximum amount.`, 'error');
                return false;
            }
        }

        for (const cartItem of this.cart) {
            cartItem.quantity = parseInt(cartItem.quantity?.toString().replaceAll(',', ''));
            if (!Number.isInteger(parseFloat(cartItem.quantity))) {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Error', 'Quantity must be in whole numbers.', 'error');
                return false;
            } else if (cartItem.quantity <= 0) {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Error', 'You must have a valid quantity.', 'error');
                return false;
            } else if (cartItem.quantity < cartItem.minimum && cartItem.productCategory?.name !== 'Services') {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Error', 'Quantity for one or more items below minimum required.', 'error');
                return false;
            } else if (cartItem.quantity > cartItem.maximum && cartItem.productCategory?.name !== 'Services') {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Error', 'Quantity for one or more items above maximum allowed.', 'error');
                return false;
            } else if (!cartItem.character && cartItem.productCategory?.name !== 'Accounts' && cartItem.game?.shortName !== 'LOL' && cartItem.game?.shortName !== 'LOLBOOSTING' && cartItem.game?.shortName !== 'VALBOOSTING' || cartItem.character?.indexOf(' ') === 0) {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Enter the character name', 'Please enter character name for each product.', 'Info');
                return false;
            } else if (cartItem.character && !this.sessionService.validateCharacterName(cartItem) && cartItem.productCategory?.name !== 'Accounts' && cartItem.game?.shortName !== 'LOL' && cartItem.game?.shortName !== 'LOLBOOSTING' && cartItem.game?.shortName !== 'VALBOOSTING') {
                this.summaryButtonState = 'valid';
                this.toastService.showToast('Same character in multiple same products', '2 of the same product contain the same character name.', 'Info');
                return false;
            }
        }
        return true;
    }

    getCouponDiscount(products, coupon) {
        // Take into account the services that are product due to the property for them being name productId
        // There are some other validation being done based on the name of property in other files.
        products = products.map((e) => {
            if (e.productId) {
                e.id = e.productId;
            }
            return e;
        });
        const productsMatchingCoupons = this.getValidatedProductsAgainstCoupons(products, coupon);
        if (productsMatchingCoupons?.length > 0) {
            return productsMatchingCoupons?.map((e) => this.getValueToBeAppliedBaseOnPriority(e, coupon)).reduce((sum, a) => sum + a, 0);
        }
        return 0;
    }

    getValidatedProductsAgainstCoupons(products, coupon) {
        const productsByCouponProduct = products
            .filter(x => coupon?.productCategories?.some(y => y.productCategoryId === x.productCategoryId)
                && coupon?.games?.some(y => y.gameId === x.gameId)
                && coupon?.products?.some(y => y.productId === x.id));
        const productsByCouponGame = products
            .filter(x => coupon?.productCategories?.some(y => y.productCategoryId === x.productCategoryId)
                && coupon?.games?.some(y => y.gameId === x.gameId));
        const productsByCouponCategory = products
            .filter(x => coupon?.productCategories?.some(y => y.productCategoryId === x.productCategoryId));

        let productsFiltered = productsByCouponProduct?.length ? productsByCouponProduct : productsByCouponGame?.length ? productsByCouponGame : productsByCouponCategory;

        //If product relations in coupon, remove all other products where game and category are same as products in relation
        if (coupon?.products?.length > 0) {
            const productsFromCouponRelationWithProducts = productsFiltered.filter(x => coupon?.products?.some(y => y.productId === x.id));
            productsFiltered = productsFiltered.filter(p => !productsFromCouponRelationWithProducts.some(x => x.productCategoryId === p.productCategoryId && x.gameId === p.gameId));
            productsFiltered = productsFiltered.concat(productsFromCouponRelationWithProducts);
        }
        return productsFiltered.length > 0 ? productsFiltered : products;
    }

    /**
     * Gets the lesser disccount possible from all the possible disccount related from 1 product to the coupon
     * @param {Coupon} coupon
     * @param {Product} product
     * @return Lesser discount possible
     */
    getValueToBeAppliedBaseOnPriority(product, coupon) {
        const valuesList = this.getValuesListBasedOnCouponAndProduct(product, coupon);
        const valueToUse = valuesList.slice(-1)[0];
        return valueToUse.type === 'Percent' ? (product.total / 100) * valueToUse.value : valueToUse.value;
    }

    /**
     * Retrieves the list of all possible discount set in the many to many relation between coupon and category/game/product
     * @param {Coupon} coupon
     * @param {Product} product
     * @returns List with all possible values
     */
    getValuesListBasedOnCouponAndProduct(product, coupon) {
        const valuesList = [];
        const defaultValue = { type: coupon.type, value: coupon.value };
        const categoryRelationValue = coupon.productCategories.filter(x => x.productCategoryId === product.productCategoryId).map((e) => { return { type: e.type, value: e.value }; });
        const gameRelationValue = coupon.games.filter(x => x.gameId === product.gameId).map((e) => { return { type: e.type, value: e.value }; });
        const productRelationValue = coupon.products.filter(x => x.productId === product.id).map((e) => { return { type: e.type, value: e.value }; });
        valuesList.push(defaultValue);
        categoryRelationValue.length && categoryRelationValue[0].value > 0 ? valuesList.push(categoryRelationValue[0]) : null;
        gameRelationValue.length && gameRelationValue[0].value > 0 ? valuesList.push(gameRelationValue[0]) : null;
        productRelationValue.length && productRelationValue[0].value > 0 ? valuesList.push(productRelationValue[0]) : null;
        return valuesList;
    }

    async startOrder() {
        try {
            if (this.helper.includesSome(this.selectedPaymentMethod.paymentMethod.reference, ['bluesnap-checkout', 'skrill-direct']) && !this.validBilling()) {
                return this.toastService.showToast('Error', 'Please enter a valid billing.', 'error');
            }

            this.summaryButtonState = 'processing';
            this.checkMinAndMaxValues();
            const cartProductsValid = await this.cartProductsValidation();
            if (!cartProductsValid) {
                return;
            }

            const checkForStartVerification = await this.checkForStartVerification();

            if (checkForStartVerification) {
                await this.veriffViewModel.startVerification();
            } else {
                const orderData = await this.buildOrder();
                await this.storeGameCharacters();
                this.helper.handleGtagEvent('begin_checkout', this.cart, 'USD', this.totalPrice, this.coupon?.code);
                this.helper.handleFacebookPixelEvent('InitiateCheckout', this.cart, 'USD', this.totalPrice);
                const order = await this.orderService.startOrder(orderData);
                if (!order) {
                    this.handleError('Failed to create order');
                    this.paymentProcessing = false;
                    return;
                }

                this.finish(order);
            }
        } catch (e) {
            this.handleError(e);
        }
    }

    handlePaymentMethodSpecificActions(paymentMethodReference) {
        if (paymentMethodReference === 'bluesnap') {
            this.sessionService.refreshProfile();
        }

        if (this.helper.includesWithout(paymentMethodReference, this.automaticPaymentMethods, this.automaticPaymentMethodsWithout)) {
            this.vgsDebitCreditCardService.getUserCardData(true);
        }
    }

    shouldRedirectToExternalService(order) {
        const externalServices = ['ideal', 'sofort', 'checkout'];
        return this.helper.includesSome(order.paymentMethod.reference, externalServices);
    }

    handleExternalRedirect(order) {
        const url = this.getExternalRedirectUrl(order);

        if (url) {
            this.router.navigate(url);
        } else {
            this.navigateToRoute(order);
        }
    }

    getExternalRedirectUrl(order) {
        if (order.hostedUrl) {
            // Coinbase Commerce Order & iDEAL (BlueSnap) & Coinpayments & BTCPay & BitCart & Sofort & Skrill Direct
            return order.hostedUrl;
        }
        if (order.g2aToken) {
            // G2A Orders
            return `https://checkout.pay.g2a.com/index/gateway?token=${order.g2aToken}`;
        }
        if (order.paypalOrderAggregate && order.paypalOrderAggregate.paymentUrl) {
            // Paypal orders
            return order.paypalOrderAggregate.paymentUrl;
        }
        return null;
    }

    navigateToRoute(order) {
        const url = this.getExternalRedirectUrl(order);
        const baseRoute = `order-details/${order.id}`;
        const blockedParam = url && !this.checkOpenWindow(url) ? '&blocked=true' : '';
        this.router.navigate(`${baseRoute}${blockedParam}`);
    }

    async finish(order) {
        this.sessionService.savePurchased(true);

        this.handlePaymentMethodSpecificActions(order.paymentMethod.reference);

        if (order.externalTransactionId && order.externalTransactionId.trim() !== '') {
            return this.processExternalPaymentProviderOrder(order);
        }
        if (this.shouldRedirectToExternalService(order)) {
            this.handleExternalRedirect(order);
        } else {
            this.navigateToRoute(order);
        }
    }

    processExternalPaymentProviderOrder(order) {
        const paymentTab = window.open(order.hostedUrl, '_blank');

        if (!paymentTab) {
            this.toastService.showToast('Error', 'Unable to open the payment window. Please check your browser settings.', 'error');
            return;
        }

        const navigateToOrderComplete = () => {
            this.router.navigate(`order-details/${order.id}`);
        };

        const maxWaitTime = 10000;
        const navigationTimeout = setTimeout(() => {
            navigateToOrderComplete();
        }, maxWaitTime);

        const checkTabClosed = () => {
            if (paymentTab.closed) {
                clearTimeout(navigationTimeout);
                navigateToOrderComplete();
            } else {
                setTimeout(checkTabClosed, 500);
            }
        };

        // Start checking if the tab is closed
        checkTabClosed();
    }

    checkOpenWindow(url) {
        const checkOutWindow = window.open(url);
        if (!checkOutWindow || checkOutWindow.closed || typeof checkOutWindow.closed === 'undefined') {
            return false;
        } else {
            return true;
        }
    }

    async buildOrder() {
        this.chargeData = null;
        this.cart.forEach((e) => {
            if (!e.productId) {
                e.productId = e.id;
            }
            e.quantity = parseFloat(e.quantity.toString().replaceAll(',', ''));
        });
        return {
            websiteShortCode: websiteShortCode(),
            totalPrice: this.totalPrice,
            products: this.cart,
            services: this.cart.filter(item => item.isService),
            currencyUsed: this.preferredCurrency,
            paymentMethodId: this.selectedPaymentMethod.paymentMethodId,
            status: this.selectedPaymentMethod?.paymentMethod.reference + ':created',
            address: this.billing.street,
            city: this.billing.city,
            state: this.billing.state,
            country: this.billing.country,
            zip: this.billing.zip,
            couponCodeString: this.coupon?.code,
            referralLinkUrl: this.sessionService.getReferralLink(),
            referrerLinkUrl: this.sessionService.getReferrerLink(),
            blueSnapPfToken: this.bluesnapToken,
            firstName: this.firstNameOnCard,
            lastName: this.lastNameOnCard,
            cardLastFour: this.cardLastFourDigits,
            cardType: this.cardType,
            blueSnapThreeDResult: this.threeDResult,
            paymentToken: this.paymentToken,
            blueSnapFraudSessionId: window.fraudSessionId,
            paysafeCardId: this.paysafeCardId,
            paysafeBillingAddressId: this.paysafeBillingAddressId,
            paysafeAuthenticationId: this.paysafeAuthenticationId,
            cardCvv: this.cardCvv,
            userDocumentIds: this.orderDocuments,
            idealBank: this.idealBank,
            payWithBalance: this.user.balanceEnabled && this.user.balance > 0,
            orderAdClicks: this.createOrderAdClicksList(),
            checkoutInstrumentId: this.checkoutInstrumentId,
            checkoutDeviceSessionId: this.checkoutDeviceSessionId,
            stripeRadarSession: this.stripeRadarSession,
            googlePayPaymentData: { googlePayPaymentToken: this.googlePayPaymentData },
            applePayPaymentData: this.applePayPaymentData,
            googleAnalyticsClientId: this.getGoogleTagManagerClientId(),
            vgsData: this.vgsData,
            threeDsData: this.threeDsData,
            sardineSessionKey: this.user.sardineSessionKey
        };
    }

    async storeGameCharacters() {
        for (const item of this.cart) {
            this.sessionService.saveGameCharacter(item?.game?.shortName, item.character);
        }
    }

    async backToPreviousPage() {
        if (this.loggingIn && this.selectedPaymentMethod) {
            this.loggingIn = false;
        } else if (this.showingPhoneAuthToken) {
            this.showingPhoneAuthToken = false;
            this.shouldShowPhoneNumber = true;
        } else if (!this.loggingIn && !this.usingPreviousCard && this.helper.includesWithout(this.selectedPaymentMethod?.paymentMethod?.reference, this.automaticPaymentMethods, this.automaticPaymentMethodsWithout)) {
            if (this.selectedPaymentMethod?.paymentMethod.reference === 'paysafe') {
                if (this.user) this.paysafeVaultedProfile = await this.customerService.getPaysafeVaultProfile();
                if (this.paysafeVaultedProfile?.cards?.length > 0) {
                    this.usingPreviousCard = true;
                    this.shouldShowBlueLine = true;
                    if (!this.checkIfButtonInLoadingState()) this.summaryButtonState = 'active';
                    return;
                } else {
                    this.selectedPaymentMethod = undefined;
                }
            } else if (this.selectedPaymentMethod?.paymentMethod.reference === 'checkout') {
                if (this.user) this.checkoutVaultedProfile = await this.customerService.getCheckoutVaultProfile();
                if (this.checkoutVaultedProfile?.instruments?.length > 0) {
                    this.usingPreviousCard = true;
                    this.shouldShowBlueLine = true;
                    if (!this.checkIfButtonInLoadingState()) this.summaryButtonState = 'active';
                    return;
                } else {
                    this.selectedPaymentMethod = undefined;
                }
            }
        } else if (!this.loggingIn && this.selectedPaymentMethod) {
            this.selectedPaymentMethod = undefined;
        }
        if (!this.checkIfButtonInLoadingState()) this.summaryButtonState = 'disabled';
    }

    createOrderAdClicksList() {
        const orderAdClicks = [];
        if (this.sessionService.getPlatformLinkCookie('gclid')) {
            orderAdClicks.push({
                service: 'Google',
                clickId: this.sessionService.getPlatformLinkCookie('gclid')
            });
        }
        if (this.sessionService.getPlatformLinkCookie('msclkid')) {
            orderAdClicks.push({
                service: 'Bing',
                clickId: this.sessionService.getPlatformLinkCookie('msclkid')
            });
        }
        return orderAdClicks;
    }

    handleCheckoutOrder(paymentToken, card, deviceSessionId) {
        if (!this.validBilling()) {
            this.toastService.showToast('Error', 'Please enter a valid billing address to proceed with your payment.', 'error');
            this.paymentProcessing = false;
            this.summaryButtonState = 'valid';
            return;
        }
        this.paymentToken = paymentToken;
        this.cardCvv = card.last4;
        this.cardType = card.scheme;
        this.checkoutInstrumentId = card.id;
        this.checkoutDeviceSessionId = deviceSessionId;
        this.startOrder();
    }

    validBilling() {
        if ((!this.postCodeValidatorExistsForCountry(this.billing.country) || (this.postCodeValidator(this.billing.zip, this.billing.country) === true)) && this.billing && this.billing.city && this.billing.country && this.billing.street) {
            this.showBillingGreenCheckMark = true;
            const selectors = document.querySelectorAll('.google-selector');
            selectors.forEach((el) => {
                const dropdown = <HTMLElement>el.querySelector('span.mdc-select__dropdown-icon');
                dropdown.style.display = 'none';
            });
            this.showBillingErrorCheckMark = !this.showBillingGreenCheckMark;
            return true;
        } else {
            this.showBillingErrorCheckMark = true;
            const selectors = document.querySelectorAll('.google-selector');
            selectors.forEach((el) => {
                const dropdown = <HTMLElement>el.querySelector('span.mdc-select__dropdown-icon');
                dropdown.style.display = 'none';
            });
            this.showBillingGreenCheckMark = !this.showBillingErrorCheckMark;
            return false;
        }
    }

    handlePaysafeOrder(paymentToken, card) {
        if (!this.validBilling()) {
            this.toastService.showToast('Billing error', 'Please enter a valid billing address to proceed with your payment.', 'Error');
            this.paymentProcessing = false;
            this.summaryButtonState = 'valid';
            return;
        }
        this.paymentToken = paymentToken;
        this.paysafeCardId = card?.id;
        this.paysafeBillingAddressId = card?.billingAddressId;
        this.paysafeAuthenticationId = card?.authenticationId;
        this.cardCvv = card?.cvv;
        this.startOrder();
    }

    getGoogleTagManagerClientId() {
        let clientId = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop();
        return clientId ? clientId = clientId.replace('GA1.1.', '') : null;
    }

    handleGooglePayDirectOrder(googlePayPaymentData, deviceSessionId) {
        if (!this.validBilling()) {
            this.toastService.showToast('Error', 'Please enter a valid billing address to proceed with your payment.', 'error');
            this.paymentProcessing = false;
            this.summaryButtonState = 'valid';
            return;
        }
        if (!googlePayPaymentData && !this.vgsData) {
            this.toastService.showToast('Error', 'Missing Google Pay configuration data', 'error');
            this.paymentProcessing = false;
            this.summaryButtonState = 'valid';
            return;
        }
        this.googlePayPaymentData = googlePayPaymentData;
        this.checkoutDeviceSessionId = deviceSessionId;
        this.startOrder();
    }

    handleApplePayDirectOrder(applePayPaymentData, deviceSessionId) {
        if (!this.validBilling()) {
            this.toastService.showToast('Error', 'Please enter a valid billing address to proceed with your payment.', 'error');
            this.paymentProcessing = false;
            this.summaryButtonState = 'valid';
            return;
        }
        if (!applePayPaymentData && !this.vgsData) {
            this.toastService.showToast('Error', 'Missing Apple Pay configuration data', 'error');
            this.paymentProcessing = false;
            this.summaryButtonState = 'valid';
            return;
        }
        this.applePayPaymentData = applePayPaymentData;
        this.checkoutDeviceSessionId = deviceSessionId;
        this.startOrder();
    }

    checkIfUserSuspiciousForVeriff = async() => {
        if (this.helper.includesSome(this.selectedPaymentMethod?.paymentMethod?.reference, ['crypto', 'coinpayments', 'btcpay', 'bitcart'])) return;
        if (this.billing?.street) {
            const geoCoder = new google.maps.Geocoder();
            let geoCoderAddress;
            try {
                geoCoderAddress = await geoCoder.geocode({ address: this.billing.street });
            } catch (e) {
                geoCoderAddress = await geoCoder.geocode({ address: `${this.billing.street} ${this.billing.zip}` });
            }
            this.geoCoderLatitude = geoCoderAddress.results[0].geometry.location.lat() ?? 0;
            this.geoCoderLongitude = geoCoderAddress.results[0].geometry.location.lng() ?? 0;
        }
        return (this.user?.phoneCountryFlag !== null && this.user?.phoneCountryFlag !== this.billing?.country?.toLowerCase())
            || (this.billing?.street ? !isPointWithinRadius(
                {
                    latitude: this.sessionService?.geolocation?.latitude ?? 0,
                    longitude: this.sessionService?.geolocation?.longitude ?? 0
                },
                {
                    latitude: this.geoCoderLatitude,
                    longitude: this.geoCoderLongitude
                },
                200000
            ) : false);
    };

    checkIfButtonInLoadingState = () => this.summaryButtonState === 'processing';

    checkForStartVerification = async() => {
        if (this.user.idVerified) return false;

        const checkVeriffLocation = this.siteSettingsKeys?.find(x => x.key === 'CheckVeriffLocation');
        const checkMultipleCards = this.siteSettingsKeys?.find(x => x.key === 'CheckMultipleCards');

        if (checkVeriffLocation && parseInt(checkVeriffLocation?.value) > 0 && await this.checkIfUserSuspiciousForVeriff()) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in cart', reason: 'Suspicious Location' });
            return true;
        }

        if ((await this.paymentMethodWebsiteService.getVerificationThresholds(this.totalSpentByUser, this.selectedPaymentMethod, this.totalPrice)).amountRequiresVeriff && this.idVerificationCriteriaMet) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in cart', reason: 'Thresholds' });
            return true;
        }

        if (!this.threeDsData && this.forceVerification && this.helper.excludeAll(this.selectedPaymentMethod.paymentMethod.reference, this.automaticPaymentMethodsWithout3DS) && this.helper.includesSome(this.selectedPaymentMethod.paymentMethod.reference, this.automaticPaymentMethods)) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in cart', reason: 'No 3DS Data' });
            return true;
        }

        const multipleUsersBySameCardExists = await this.vgsDebitCreditCardService.multipleUsersBySameCardExists(this.vgsData?.cardNumber ?? this.vgsData?.id);
        const multipleUsersBySameCard = multipleUsersBySameCardExists.multipleUsersSameCardOrDifferentProfiles || multipleUsersBySameCardExists.useTwoCardsWithin24Hours;
        if (checkMultipleCards && parseInt(checkMultipleCards?.value) > 0 && multipleUsersBySameCard && this.helper.includesSome(this.selectedPaymentMethod.paymentMethod.reference, this.automaticPaymentMethods)) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in cart', reason: `Two cards used in 24 hours (${multipleUsersBySameCardExists.useTwoCardsWithin24Hours}) - Card used in different profiles (${multipleUsersBySameCardExists.multipleUsersSameCardOrDifferentProfiles})` });
            return true;
        }

        if (this.threeDsData && this.threeDsData.status !== 'Y' && this.helper.excludeAll(this.selectedPaymentMethod.paymentMethod.reference, this.automaticPaymentMethodsWithout3DS)) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in cart', reason: '3DS authentication failed' });
            return true;
        }

        if (this.user.userBlacklist?.length) {
            await this.blockActionLogService.create({ userId: this.user.id, actionType: 'Veriff Prompt in cart', reason: 'User Blacklisted' });
            return true;
        }

        return false;
    };

    @computedFrom('selectedPaymentMethod.paymentMethod.reference', 'paymentMethodListForShowingBlueLine')
    get isGooglePaymentMethod() {
        if (!this.selectedPaymentMethod) return false;
        return this.helper.includesSome(this.selectedPaymentMethod.paymentMethod.reference, this.paymentMethodListForShowingButton);
    }

    @computedFrom('selectedPaymentMethod.paymentMethod.reference')
    get isManualPaymentMethod() {
        if (!this.selectedPaymentMethod) return false;

        return this.helper.includesWithout(
            this.selectedPaymentMethod?.paymentMethod?.reference,
            ['manual', 'zelle', 'alipay', 'cash', 'western-union', 'interac'],
            ['bluesnap-checkout', 'coinpayments']
        );
    }
}
